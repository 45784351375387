.s-g-container {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.s-g-button {
  width: 175px;
  height: 64px;
  align-self: center;
  text-align: center;
}
.s-g-root-class-name {
  margin-bottom: 20px;
}
