.press-container {
  width: 177px;
  height: 222px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.press-container1 {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.press-button {
  width: 129px;
  height: 134px;
  text-align: center;
}
.press-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.press-button1 {
  width: 97px;
  height: 102px;
  text-align: center;
}
.press-root-class-name {
  margin-bottom: 10px;
}
