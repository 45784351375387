.p-g-container {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
}
.p-g-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.p-g-button {
  font-size: 10px;
  margin-right: 20px;
}
.p-g-button1 {
  font-size: 10px;
  margin-right: 20px;
}
.p-g-button2 {
  font-size: 10px;
  margin-right: 20px;
}
.p-g-button3 {
  font-size: 10px;
  margin-right: 20px;
}
.p-g-button4 {
  font-size: 10px;
  margin-right: 20px;
}
.p-g-button5 {
  font-size: 10px;
  margin-right: 20px;
}
.p-g-button6 {
  font-size: 10px;
}
.p-g-root-class-name {
  margin-bottom: 50px;
}
