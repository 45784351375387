.c-n-c-m-container {
  width: 100%;
  height: 360px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: right;
}
.c-n-c-m-button {
  width: 89px;
  height: 50px;
  text-align: center;
}
.c-n-c-m-root-class-name {
  margin-left: 20px;
}
