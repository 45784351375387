.mill-container {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mill-button {
  margin-bottom: 10px;
}
.mill-button1 {
  margin-bottom: 10px;
}

