.lathe-container {
  width: 100%;
  height: 340x;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.lathe-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.lathe-button {
  font-size: 10px;
}
.lathe-button01 {
  font-size: 10px;
}
.lathe-button02 {
  font-size: 10px;
}
.lathe-button03 {
  font-size: 10px;
}
.lathe-container2 {
  width: 100%;
  height: 138px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lathe-button04 {
  font-size: 10px;
}
.lathe-button05 {
  font-size: 10px;
}
.lathe-button06 {
  font-size: 10px;
}
.lathe-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
}
.lathe-button07 {
  font-size: 10px;
  margin-right: 20px;
}
.lathe-button08 {
  font-size: 10px;
}
.lathe-button09 {
  font-size: 10px;
}
.lathe-button10 {
  font-size: 10px;
}

