.workshop-layout-container {
  width: 1290px;
  height: 70vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: right;
}
.workshop-layout-container01 {
  width: 1195px;
  height: 513px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("../../assets/images/layout.jpg");
  background-position: center;
}
.workshop-layout-container02 {
  width: 177px;
  height: 502px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.workshop-layout-container03 {
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.workshop-layout-container04 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 126px;
  display: flex;
  align-items: flex-start;
}
.workshop-layout-container05 {
  width: 424px;
  height: 497px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.workshop-layout-container06 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.workshop-layout-container07 {
  width: 100%;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.workshop-layout-container08 {
  width: 242px;
  height: 493px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.workshop-layout-container09 {
  width: 100%;
  height: 261px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.workshop-layout-container10 {
  width: 100%;
  height: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.workshop-layout-container11 {
  width: 165px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.workshop-layout-container12 {
  width: 100%;
  height: 153px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.workshop-layout-container13 {
  width: 100%;
  height: 353px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
