.e-d-m-container {
  width: 100%;
  height: 102px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

